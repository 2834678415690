export default [
  {
    header: "샘플 페이지",
    icon: "LayersIcon",
    children: [
      {
        title: "샘플 페이지",
        route: "sample-page",
        icon: "CopyIcon",
      },
    ],
  },
];
