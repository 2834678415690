<template>
  <div>
    <div>
      10223 경기도 고양시 일산서구 고양대로 283
    </div>
    <div>
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.kict.re.kr/"
        target="_blank"
      >한국건설기술연구원
      </b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
