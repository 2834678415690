export default [
  {
    header: "통계/보고서",
    icon: "LayersIcon",
    children: [
      {
        title: "통계 조회",
        route: "",
        icon: "ActivityIcon",
      },
      {
        title: "검진 필터 조회",
        route: "",
        icon: "PlusCircleIcon",
      },
      {
        title: "보고서",
        route: "",
        icon: "FileTextIcon",
      },
    ],
  },
];
